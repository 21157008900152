//Migrated
<template lang="pug">
.alert.alert-custom(:class="classes")
  .row
    .col-12.alert-custom-icon.d-flex.align-items-center(:class="iconClass")
      slot(name="icon")
        fa.svg-inline--fa(
          :class="`fa-${iconSize}`"
          :icon="alertIcon"
        )
    .col-12.d-flex.align-items-center.alert-custom-content.font-weight-bolder(:class="contentClasses")
      slot
</template>

<script>
import { faSadTear, faCircleCheck, faTriangleExclamation, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'

export default defineNuxtComponent({
  props: {
    parentClass: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'error'
    },

    maxContentWidth: {
      type: Boolean,
      default: false
    },

    iconSize: {
      type: String,
      default: '5x'
    },

    contentClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      icons: {
        error: faSadTear,
        warning: faTriangleExclamation,
        success: faCircleCheck,
        info: faCircleExclamation
      }
    }
  },

  computed: {
    alertIcon () {
      return this.icons[this.type] || this.icons.info
    },

    alertType () {
      switch (this.type) {
        case 'error': return 'danger'
        case 'warning': return 'warning'
        case 'success': return 'success'
        case 'info': return 'info'
      }
      return 'light'
    },

    classes () {
      return [
        ...this.parentClass,
        `alert-${this.alertType}`
      ]
    },

    iconClass () {
      return this.maxContentWidth
        ? 'col-md-2 col-xl-1 text-left'
        : 'col-md-4 col-xl-3 text-center'
    },

    contentClasses () {
      const classes = [this.contentClass]

      if (this.maxContentWidth) {
        classes.push('col-md-10 col-xl-11')
      } else {
        classes.push('col-md-8 col-xl-9')
      }

      return classes.join(' ')
    }
  }
})
</script>

<style lang="scss">
.alert-custom-icon {
  & > svg {
    max-width: 100%;
    max-height: 35px;
    height: 100%;
  }
}
</style>
